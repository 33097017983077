<template>
  <div class="forget_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner_login.jpg" />
      </div>
    </div>

    <div class="container content">
      <div class="forget_text">
        <div class="title">Forgotten Password</div>
        <div class="text">
          Please enter your email address below to receive instructions on how
          to reset your password.
        </div>
      </div>
      <input
        class="forget_inp"
        placeholder="Enter your email address"
        v-model="email"
      />
      <!-- <div v-html="img"></div> -->
      <div class="button" @click="submit">SEND</div>
    </div>
  </div>
</template>

<script>
import { resetPwd, verifyCode } from '@/api/reg.js';
export default {
  name: 'Forget',
  components: {},
  data() {
    return {
      img: '',
      email: '',
    };
  },
  created() {
    this.getVerifyCode();
  },
  methods: {
    getVerifyCode() {
      verifyCode().then(res => {
        console.log(res);
        this.img = res;
      });
    },
    submit() {
      if (this.email != '') {
        resetPwd({ email: this.email }).then(res => {
          console.log(res);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.forget_text {
  text-align: center;
  .title {
    font-weight: bold;
  }
}
.forget_inp {
  width: 90%;
  height: 40px;
  line-height: 40px;
  max-width: 450px;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #000;
  display: block;
  margin: 50px auto;
}
</style>
